import {
  init,
  browserTracingIntegration,
  extraErrorDataIntegration,
  captureConsoleIntegration,
  httpClientIntegration,
} from '@sentry/browser'

import settings, { addUserToSentry } from '../sharedWithConsole/sentrySettings.ts'

init({
  ...settings,
  integrations: [
    browserTracingIntegration({
      enableInp: true,
    }),
    // This integration extracts all non-native attributes from the error object and attaches
    // them to the event as the extra data. If the error object has a .toJSON() method, the
    /// ExtraErrorData integration will run it to extract additional information.
    extraErrorDataIntegration({
      captureErrorCause: true,
    }),
    // This integration captures all Console API calls and redirects them to Sentry using the
    // SDK's  captureMessage or captureException call, depending on the log level.  It then
    // re-triggers to preserve default native behavior.
    captureConsoleIntegration({
      levels: ['error', 'warn'],
    }),
    // This integration captures failed fetch/xhr requests.
    httpClientIntegration({
      failedRequestStatusCodes: [[400, 599]],
    }),
  ],
})

addUserToSentry()
